import React, { useState, useEffect, useRef,createRef } from 'react';
import './style.scss';

const Dropdown = ({ options, placeholder, onChange, value }) => {

  const [isOpen, setIsOpen] = useState(false);
  const [selectedOption, setSelectedOption] = useState(null);
  const [focusIndex, setFocusIndex] = useState(-1);

  const wrapperRef = useRef(null);
  const optionRefs = useRef([]);
  optionRefs.current = options.map((_, i) => optionRefs.current[i] ?? createRef());

  useEffect(() => {
    if (isOpen && focusIndex >= 0 && focusIndex < options.length) {
      optionRefs.current[focusIndex].current.focus();
    }
  }, [focusIndex, isOpen, options.length]);

  useEffect(() => {
    setSelectedOption(value);
  }, [value]);

  useEffect(() => {
    function handleClickOutside(event) {
      if (wrapperRef.current && !wrapperRef.current.contains(event.target)) {
        setIsOpen(false);
      }
    }
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [wrapperRef]);

  const handleKeyDown = (event) => {
    if (event.key === 'ArrowDown') {
      event.preventDefault();
      setFocusIndex((prevIndex) => (prevIndex + 1) % options.length);
    } else if (event.key === 'ArrowUp') {
      event.preventDefault();
      setFocusIndex((prevIndex) => (prevIndex - 1 + options.length) % options.length);
    } else if (event.key === 'Enter' && focusIndex >= 0 && focusIndex < options.length) {
      event.preventDefault();
      setSelectedOption(options[focusIndex]);
      onChange(options[focusIndex]);
      setIsOpen(false);
    } else if (event.key === 'Escape') {
      event.preventDefault();
      setIsOpen(false);
    }
  };

  const handleOptionClick = (option) => {
    setSelectedOption(option);
    setIsOpen(false);
    onChange(option);
  };
  return (
    <div className="custom-select-wrapper" ref={wrapperRef} onKeyDown={handleKeyDown} tabIndex="0">
    <div className={`custom-select ${isOpen ? 'opened' : ''}`} onClick={() => setIsOpen(!isOpen)}>
      <span className="custom-select-trigger">{selectedOption || placeholder}</span>
      {isOpen && (
        <div className="custom-options" >
          {options.map((option, index) => (
            <span
            ref={optionRefs.current[index]}
              key={index}
              className={`custom-option ${selectedOption === option ? 'selection' : ''} ${focusIndex === index ? 'selection' : ''}`}
              onClick={() => handleOptionClick(option)}
              tabIndex="-1"
            >
              {option}
            </span>
          ))}
        </div>
      )}
    </div>
  </div>
    )
}

export default Dropdown