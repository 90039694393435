import React from 'react'
import ReactImageGallery from 'react-image-gallery';
import RenderItem from './RenderItem';
import "react-image-gallery/styles/css/image-gallery.css"
// import Slider from " react-slick";
// import "slick-carousel/slick/slick.css";
// import "slick-carousel/slick/slick-theme.css";
import "./modal.scss";

const Modal = ({ isOpen, onClose, images, input }) => {
  // const input = "https://storage.googleapis.com/generativeartbucket/UserGenerations/cristian/APIUser/input-20240511-110344536841.png"
  // const images = [
  //   {
  //     original: "https://storage.googleapis.com/generativeartbucket/UserGenerations/cristian/APIUser/20240511-110344536841-1.png",
  //   },
  //   {
  //     original: "https://storage.googleapis.com/generativeartbucket/UserGenerations/cristian/APIUser/20240511-110344536841-2.png",
  //   }        // "https://storage.googleapis.com/generativeartbucket/UserGenerations/cristian/APIUser/20240507-070004876397-3.png",
  //   // "https://storage.googleapis.com/generativeartbucket/UserGenerations/cristian/APIUser/20240507-070004876397-4.png"
  // ];

  return (
    <dialog id="dialog" open={isOpen || null} >
      {/* <Slider {...settings}>
         <RenderItem output={images[0].original} input={input} />
         <RenderItem output={images[1].original} input={input} />
       </Slider> */}
      <ReactImageGallery disableSwipe stopPropagation={false} showPlayButton={false} useBrowserFullscreen={false} bulletClass="bullet" originalAlt={'Future Home AI image'} originalTitle="FutureHomeAIImage" showBullets items={images} renderItem={(e) => <RenderItem output={e.original} input={input} />} />
      <button onClick={onClose} aria-label="close" className="x">❌</button>
    </dialog>
  )
}

export default Modal