import React from 'react'
import logo from '../assets/images/logo-white.jpg';
import Form from '../components/Form';
import { useNavigate } from 'react-router-dom';
import home from "../assets/icons/house-blank-light.svg";

const Tool = () => {
    const navigate = useNavigate();
  return (
    <>
    <div className='logo'>
        <img src={home} alt="Home"  className="breadcrumb" onClick={() => navigate('/')} width={80} height={60}/>
        <img id='logo' src={logo} alt="logo" width={120} height={70} />
      </div>
      <div className='title'>
        <h4 className='appTitle'>AI Dream Home Design</h4>
      <p className='appDescription'>Design Your room with our AI tool</p>
      </div>
      <Form />
      
    </>
  )
}

export default Tool