import React, { useEffect, useState } from 'react';
import { useDropzone } from 'react-dropzone';
import './styles.scss';

const FileDropzone = ({ onFileUpload, onChange, init, setFile }) => {
  const [previewUrl, setPreviewUrl] = useState(init || null);

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop: (acceptedFiles) => {
      acceptedFiles.forEach(file => {
        if (!['image/jpeg', 'image/png'].includes(file.type)) {
          alert('Invalid file type. The image must be a .jpg, .jpeg, or .png file.');
          return;
        }
        const img = new Image();
        img.onload = function() {
          if (this.width < 512 || this.height < 512) {
            alert('The image dimensions must be 512x512 pixels or greater for optimal results.');
            return;
          }
          const url = URL.createObjectURL(file);
          setPreviewUrl(url);
          onFileUpload([file]);
          onChange(file);
        };
        img.src = URL.createObjectURL(file);
      });
    },
  });

  useEffect(() => {
    const handlePaste = (event) => {
      const items = (event.clipboardData || event.originalEvent.clipboardData).items;
      for (let index in items) {
        const item = items[index];
        if (item.kind === 'file') {
          const blob = item.getAsFile();
          const reader = new FileReader();
          reader.onload = function(event) {
            const img = new Image();
            img.onload = function() {
              if (this.width < 512 || this.height < 512) {
                alert('The image dimensions must be 512x512 pixels or greater for optimal results.');
                return;
              }
              setPreviewUrl(event.target.result);
              onFileUpload([blob]);
              onChange(blob);
            };
            img.src = event.target.result;
          }; 
          reader.readAsDataURL(blob);
        }
      }
    };
    window.addEventListener('paste', handlePaste);
    return () => {
      window.removeEventListener('paste', handlePaste);
    };
  }, [onChange, onFileUpload]);

  const handleResetClick = () => {
    setPreviewUrl(null);
    setFile(null);
    onChange(null); // Reset the field value in the form data
  };
  return (
    previewUrl ? (
      <div className="previewWrapper" >
        <img src={previewUrl} alt="Preview" />
        {/* close icon reset the selected file*/}
        <button className='resetButton' onClick={handleResetClick}>X</button>
      </div>
    ) : (
      <div {...getRootProps()} className={`dropzone ${isDragActive ? 'active' : ''}`}>
        <input {...getInputProps()} />
        <span>Drag 'n' drop an image here, or click to select an image</span>
      </div>
    )
  );
};

export default FileDropzone;