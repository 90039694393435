import axios from 'axios';

const api = axios.create({
    baseURL: process.env.REACT_APP_API_URL 
});
// use Authorization: Bearer <token> for authentication
api.interceptors.request.use((config) => {
    config.headers.Authorization = `Bearer ${process.env.REACT_APP_TOKEN}`;
    return config;
});

export const beautifulRedesign = async (data) => {
    try {
        const response = await api.post('/beautiful_redesign', data);
        return response.data.success;
    } catch (error) {
        console.error(error?.response?.data?.message);
    }
}

export const creativeRedesign = async (data) => {
    try {
        const response = await api.post('/creative_redesign', data);
        return response.data.success;
    } catch (error) {
        console.error(error);
    }
}


export const enhance = async (data) => {
    try {
        const response = await api.post('/enhance', data);
        return response.data;
    } catch (error) {
        console.error(error);
    }
}