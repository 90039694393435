export const interiorOptions = {
        designStyle: ['No Style', 'Eclectic', 'Modern', 'Contemporary', 'Transitional', 'Scandinavian', 'Mediterranean', 'Ikea', 'Industrial', 'Kids Room', 'Shabby Chic', 'Coastal', 'Bauhaus', 'Bohemian', 'Traditional', 'Rustic', 'Minimalism', 'Japandi', 'Japanese Design', 'Modern Arabic', 'Traditional Arabic', 'Bali', 'Tropical', 'Asian Decor', 'Zen', 'Hollywood Regency', 'Hollywood Glam', 'Minimalist', 'Christmas', 'Futuristic', 'Luxurious', 'Midcentury Modern', 'Biophilic', 'Cottage Core', 'French Country', 'Art Deco', 'Art Nouveau', 'South Western', 'Modern Farm House', 'Moroccan', 'Gothic', 'Victorian', 'Steampunk', 'Urban Modern', 'Desert Modernism', 'Colonial', 'Brutalist', 'Nordic Noir', 'Postmodern', 'Psychedelic', 'Cosmic Chic', 'Mexican Hacienda', 'Coastal Modern', 'Eco Friendly', 'Pop Art', 'Vintage Glam', 'Candy Land', 'Barbie', 'Doodle', 'Sketch', 'Maximalist', 'Professional', 'AirBnb', 'Halloween', 'Retro', 'Romantic', 'Glam Rock', 'Safari', 'Tuscan', 'Nautical', 'Craftman', 'Farmhouse Chic', 'Prairie', 'Cubism'],
        roomType: ['Living room', 'Bedroom', 'Bathroom', 'Kitchen', 'Dining room', 'Attic', 'Study room', 'Home office', 'Family Room', 'Formal Dining Room', 'Home Theater', 'Kids Room', 'Balcony', 'Gaming room', 'Meeting room', 'Workshop', 'Fitness gym', 'Coffee shop', 'Clothing store', 'Restaurant', 'Office', 'Coworking space', 'Hotel lobby', 'Hotel room', 'Hotel bathroom', 'Exhibition space', 'Onsen', 'Working Space', 'Wine Cellar', 'Rooftop Terrace', 'Sunroom', 'Home Spa', 'Mudroom', 'Craft Room', 'Dressing Room', 'Guest Bedroom', 'Home Bar', 'Library', 'Art Studio', 'Yoga Studio', 'Photo Studio', 'Multimedia Room', 'Auditorium', 'Medical Exam Room', 'Reception Area', 'Music Room', 'Science Laboratory', 'Wedding Room', 'Laundry Room', 'Outdoor Kitchen', 'Utility Room', 'Pet Room', 'Home Gym', 'Lounge', 'Walk-in Closet', 'Playroom', 'Reading Nook', 'Sauna', 'Man Cave', 'Foyer', 'Greenhouse', 'She Shed', 'Conservatory', 'Nursery', 'Prayer Room'],

    }
export const exteriorOptions = {
    designStyle: ['No Style', 'Modern', 'Mediterranean', 'International', 'Moody Colors', 'Wood Accents', 'Bohemian', 'Industrial', 'Retreat', 'Elegant', 'Painted Brick', 'Red Brick', 'Modern Blend', 'Stone Clad', 'Glass House', 'Ranch', 'Modern Farm House', 'Portuguese', 'Traditional', 'Craftsman', 'Tudor', 'Prairie', 'Chalet', 'Colonial', 'Dutch Colonial Revival', 'Georgian', 'Green', 'Contemporary', 'Cottage', 'Farmhouse', 'French Country', 'Futuristic', 'Gothic', 'Greek Revival', 'Mansion', 'Townhouse', 'Victorian', 'Corporate Building'],
    roomType:['Side of house', 'Front of house', 'Back of house']
}

export const gardenOptions = {
    designStyle: ['No Style', 'Modern Garden', 'City Garden', 'Contemporary Garden', 'Luxury Garden', 'Apartment Garden', 'Small Garden', 'Vegetable Garden', 'Low Budget Garden', 'Beach Garden', 'Wedding Garden', 'Rural Garden', 'Mediterranean Garden', 'Restaurant Garden', 'Formal Garden', 'American Garden', 'English Garden', 'Traditional Garden', 'Cottage Garden', 'Meditation Garden', 'Coastal Garden', 'Tropical Garden'],
    roomType: ['Backyard', 'Patio', 'Terrace', 'Front Yard', 'Garden', 'Courtyard', 'Pool Area', 'Porc', 'Playground']
}