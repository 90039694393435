import React, {memo} from 'react';
import { ImgComparisonSlider } from '@img-comparison-slider/react';
import saveImg from "../../assets/icons/open-externalsvg.svg"

const RenderItem = memo(({output, input}) => {

  return (
    <>
    <button className='viewImg' style={{ position:'absolute', bottom:'10px', zIndex: 99}}>
      <a href={output} target="_blank" rel="noopener noreferrer" download>
        <img src={saveImg} alt="download" width={20} height={20} />
      </a>
    </button>
   <ImgComparisonSlider direction='vertical'>
    <img slot='first' src={input} alt="input" width={'100%'} />
    <img slot='second' src={output} alt="output" width={'100%'} />
   </ImgComparisonSlider>
   </>
  )
})

export default RenderItem;