import './scss/variables.scss';
import './App.scss';
import Home from './pages/Home';
import { Route, Routes } from 'react-router-dom';
import Tool from './pages/Tool';

function RoomDesignToolApp() {
  return (
    <>
    <div className="App">
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/room-ai-tool" element={<Tool />} />
        <Route path="*" element={<Home />} />
      </Routes>
      <footer className="footer">
            <a href='https://www.ebs.ie/privacy-statement' target='_blank ' rel="noreferrer">Privacy Policy</a>
            <div className="footerContent">
              {`© 2024 Future Home AI by EBS`}
            </div>
        </footer>
    </div>
    </>
  );
}

export default RoomDesignToolApp;
