import React from 'react';
import { useNavigate } from 'react-router-dom';

import logo from "../assets/images/logo-white.jpg";
import openIcon from "../assets/icons/circle-arrow-up-right-light.svg";
import home from "../assets/icons/house-heart-light.svg";
import whatsapp from "../assets/icons/whatsapp.svg";
import saving from "../assets/icons/piggy-bank-light.svg";
import mainIcon from "../assets/images/main-icon.png";
import Swal from 'sweetalert2';
// import 'sweetalert2/src/sweetalert2.scss'
const Home = () => {

    const navigate = useNavigate();
  return (
    <div className='home'>
        <img className='homeLogo' src={logo} alt="Room AI Tool" srcset={logo}  width={128} height={80}/>
        <div className="content">
            <span className='' >Welcome to</span>
            <h4>Future Home AI</h4>
            <p>Your personal interior designer</p>
        </div>
        <div className="mainIcon">
            {/* <img className='bhand' src={bHand} alt="Blue Hand" srcset={bHand} />
            <img className='yhand' src={yHand} alt="yellow-hand" srcset={yHand} /> */}
            <img src={mainIcon} alt="main Icon" srcset={mainIcon} width={134} height={134} />
        </div>
        <div className="opt">
            <div className="designHome" onClick={() => navigate('/room-ai-tool')}>
                <div className="icons">
                    <img src={mainIcon} alt="Main Icon" srcset={mainIcon} width={45} height={38} /> 
                <img className='rightIcon' src={openIcon} alt="Open Icon" srcSet={openIcon} width={20} height={20} />
                </div>
                <p>Start designing your Dream Home</p>
            </div>
            <div className="btnWrapper">
                <div className="homeHunter smallBox" onClick={() => window.open("https://househunterai.chat/", '_blank')}>
                <div className="icons">
                        <img src={home} alt="Roller" srcset={home} width={30} height={30} />
                    <img className='rightIcon' src={openIcon} alt="Open Icon" srcSet={openIcon} width={16} height={16} />
                    </div>
                        <p>House Hunter AI</p>
                </div>
                <div className="whatsapp smallBox " onClick={() => 
                    Swal.fire({
                        title: "Chat on WhatsApp",
                        text: 'This new feature will be available soon!',
                        showCloseButton: true,
                        showConfirmButton: false,
                        color: '#fff',
                        background: '#333',
                        
                      })
                }>
                <div className="icons">
                        <img src={whatsapp} alt="Pink Hand" srcset={whatsapp} width={30} height={30}/>
                        <img className='rightIcon' src={openIcon} alt="Open Icon" srcSet={openIcon} width={16} height={16} />
                    </div>
                        <p>Chat on WhatsApp</p>
                </div>
                <div className="saving smallBox" onClick={() => window.open("https://savingscompanion.chat/", '_blank')}>
                    <div className="icons">
                        <img src={saving} alt="Pink Hand" srcset={saving} width={30} height={30} />
                        <img className='rightIcon' src={openIcon} alt="Open Icon" srcSet={openIcon} width={16} height={16} />
                    </div>
                    <p>Savings Companion</p>
                </div>
            </div>
        </div>
    </div>
  )
}

export default Home