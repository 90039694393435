import React from 'react'

const Tooltip = ({ open, message}) => {
  return (
    <div className={`tooltip ${open ? 'open' : ''}`}>
        {message}
    </div>
  )
}

export default Tooltip