import React from 'react'
import "./styles.scss";
const InputSlider = ({ value, onChange}) => {

  return (
        <div id="ai-intervention">
          <input type="radio" name="ai-intervention" id="1" value="Very Low" onChange={(e) => onChange(e.target.value)} />
          <label for="1" data-ai-intervention="Very Low"></label>
          <input type="radio" name="ai-intervention" id="2" value="Low" onChange={onChange} />
          <label for="2" data-ai-intervention="Low"></label>
          <input type="radio" name="ai-intervention" id="3" value="Mid" onChange={onChange} />
          <label for="3" data-ai-intervention="Medium"></label>
          <input type="radio" name="ai-intervention" id="4" value="Extreme" onChange={onChange} />
          <label for="4" data-ai-intervention="Extreme"></label>
          {/* <div id="ai-intervention-pos"></div> */}
		    </div>  )
}

export default InputSlider