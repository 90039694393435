import React, { useEffect } from 'react'
import FileDropzone from './dropzone/FileDropZone';
import Dropdown from './dropdown/Dropdown';
import InputSlider from './inptutslider/InputSlider';
import Stepper from "@keyvaluesystems/react-stepper";

import { interiorOptions } from '../api/values';

import { useForm, Controller } from 'react-hook-form';
import { beautifulRedesign, creativeRedesign } from '../api';
import Modal from './modal/Modal';
import Tooltip from './Tooltip';
import Swal from 'sweetalert2';


// painting room... choosing wallpaper... installing floorboards... choosing colours... painting the walls... picking wood... last lick of paint... finishing touches... double checking... testing design... adding finishing touches.. checking design... nearly finished...
const loadingTexts = [
  "Painting Room",
  "Choosing Wallpaper",
  "Installing Floorboards",
  "Choosing Colours",
  "Painting the Walls",
  "Picking Wood",
  "Last Lick of Paint",
  "Finishing Touches",
  "Double Checking",
  "Testing Design",
  "Adding Finishing Touches",
  "Checking Design",
  "Nearly Finished",
]
const Form = () => {

  const [open, setOpen] = React.useState(false);
  const [openTooltip, setOpenTooltip] = React.useState(false);
  const [currentStep, setCurrentStep] = React.useState(0);
  const [outputImages, setOutputImages] = React.useState([]);
  const [inputImage, setInputImage] = React.useState(null);
  const [file, setFile] = React.useState(null);
  const [loadingTextIndex, setLoadingTextIndex] = React.useState(0);


  const { handleSubmit, control, getValues, trigger, reset, formState: { errors, isSubmitting } } = useForm();

  useEffect(() => {
    if (isSubmitting) {
      const interval = setInterval(() => {
        setLoadingTextIndex((prevIndex) => (prevIndex + 1) % loadingTexts.length);
      }, 5000); // Change text every 2 seconds

      return () => clearInterval(interval); // Clean up on unmount
    }
  }, [isSubmitting]);
  const handleFileUpload = (files) => {
    files.forEach((file) => {
      const reader = new FileReader();
      reader.onabort = () => console.log('file reading was aborted');
      reader.onerror = () => console.log('file reading has failed');
      reader.onload = () => {
        // Do whatever you want with the file contents
        const binaryStr = reader.result;
        console.log(binaryStr);
      };
      reader.readAsArrayBuffer(file);
      const url = URL.createObjectURL(file);
      setFile(url);
    });
  };

  const onSubmit = async (data) => {
    try {
      // setLoadingTextIndex(0);
      const formData = new FormData();
      formData.append('image', data.image);
      formData.append('room_type', data.room_type);
      formData.append('design_style', data.designStyle);
      formData.append('no_design', 2);
      formData.append('ai_intervention', data.ai_intervention);
      formData.append('design_type', 'Interior');
      let response;
      // setLoadingTextIndex(1);
      if (data.mode === `Let's get Creative!`) {
        // setLoadingTextIndex(2);
        // formData.append('fill', false);
        response = await creativeRedesign(formData);
        console.log('response', response)
      }
      else {
        // setLoadingTextIndex(3);
        response = await beautifulRedesign(formData);
      }
      // setLoadingTextIndex(4);
      setOutputImages([
        {
          original: response.generated_image[0],
        },
        {
          original: response.generated_image[1],
        }
      ]);
      // setLoadingTextIndex(4);
      setInputImage(response.original_image);
      setOpen(true);
      setFile(null);
      reset({ ...data });
      setCurrentStep(0);
    }
    catch (error) {
      console.log(error);
      Swal.fire({
        title: "Error",
        text: 'An error occurred. Please try again.',
        icon: 'error',
        showCloseButton: true,
        showConfirmButton: false,
        color: '#fff',
        background: '#333',
      });
      setOpen(false);
    }
  };

  window.onbeforeunload = isSubmitting ? () => false : null;

  const handleNext = (index, name) => {
    //  setOpen(true);
    if (index === 4) return handleSubmit(onSubmit)();
    const value = getValues(name);
    !value && trigger(name)
    value && setCurrentStep((prevStep) => prevStep + 1);
  };

  const handleBack = () => {
    setCurrentStep((prevStep) => prevStep - 1);
  };

  const handleStepClick = (step, index) => {
    setCurrentStep(index);
  };

  const steps = [
    {
      stepLabel: "Step 1",
      name: "image",
      description: "Upload Your Room Image",
      completed: false,
      element: <>
        <Controller
          name="image"
          control={control}
          rules={{ required: 'image is required' }}
          defaultValue=""
          render={({ field }) => <FileDropzone {...field} onFileUpload={handleFileUpload} init={file} setFile={setFile} />}
        />
        {errors.image && <p className="error">{errors.image.message}</p>}
        <br />
      </>,
      info: "Upload Your Room Image, It can be a bedroom, living room, kitchen, etc. The image dimensions must be 512x512 pixels or greater for optimal results."
    },
    {
      stepLabel: "Step 2",
      name: "room_type",
      description: "What Kind of Room is it?",
      completed: false,
      element: <>
        <Controller
          name="room_type"
          control={control}
          rules={{ required: 'Room is required' }}
          defaultValue=""
          render={({ field }) => <Dropdown options={interiorOptions.roomType} {...field} placeholder="Select Room" />}
        />
        {errors.room_type && <p className="error">{errors.room_type.message}</p>}
      </>,
      info: "Choose the type of room you want to redesign. This could be categories like 'Bedroom', 'Living Room', 'Kitchen', etc., based on the type of room you want to redesign."
    },
    {
      stepLabel: "Step 3",
      name: "designStyle",
      description: "Choose Your Design Style",
      completed: false,
      element: <>
        <Controller
          name="designStyle"
          control={control}
          rules={{ required: 'Design Style is required' }}
          defaultValue=""
          render={({ field }) => <Dropdown {...field} options={interiorOptions.designStyle} placeholder="Select Design Style" />}
        />
        {errors.designStyle && <p className="error">{errors.designStyle.message}</p>}
      </>,
      info: "Specify the type of design you want to apply. This could be categories like 'Modern', 'Traditional', 'Minimalist', etc., based on the design style you want to apply to your room.",
    },
    {
      stepLabel: "Step 4",
      name: "mode",
      description: "Choose Your Design Level?",
      completed: true,
      element: <>
        <Controller
          name="mode"
          control={control}
          rules={{ required: 'Mode is required' }}
          defaultValue=""
          render={({ field }) => <Dropdown {...field} options={["Make it Beautiful", "Let's get Creative!"]} placeholder="Select Design Level" />}
        />
        {errors.mode && <p className="error">{errors.mode.message}</p>}
      </>,
      info: "Specify whether the room is furnished or not. This could be categories like 'Yes', 'No', etc., based on the furnishing of the room."
    },
    {
      stepLabel: "Step 5",
      name: "ai_intervention",
      description: "Choose the Redesign Level",
      completed: false,
      element: <>
        <Controller
          name="ai_intervention"
          control={control}
          rules={{ required: 'This is required' }}
          defaultValue=""
          render={({ field }) => <InputSlider {...field} />}
        />
        {errors.ai_intervention && <p className="error">{errors.ai_intervention.message}</p>}
      </>,
      info: `Choose the level of AI intervention you want to apply to your room. This could be categories like 'Low', 'Medium', 'High', etc., "Very Low" means minimal changes, while "Extreme" allows for significant alterations to the original design.`,

    },
  ];
  return (
    <>
      <div className="formWrapper">
        <form
          onSubmit={(e) => {
            e.preventDefault();
          }}
        >
          <Stepper
            steps={steps}
            currentStepIndex={currentStep}
            orientation="vertical"
            labelPosition="top"
            showDescriptionForAllSteps={false}
            onStepClick={handleStepClick}
            renderNode={(step, i) =>
              <></>
            }
            stepContent={(step, i) => (
              <div className='wrapper'>
                <div className="labelWrapper">
                  <label >{step.description}</label>
                  <span className='stepInfo' onClick={() => {
                    setOpenTooltip(!openTooltip);
                  }}>i</span>
                  <Tooltip open={openTooltip} message={step.info} />
                </div>
                {step.element}
                <div className="stepActions">
                  <button onClick={() => handleBack(i)} disabled={i <= 0}>Back</button>
                  <p style={{ visibility: `${i === 4 && 'hidden'}` }}>Step {i + 1} of 5</p>
                  <button onClick={() => handleNext(i, step.name)} >{i === 4 ? 'Finish' : 'Next'}</button>
                </div>
              </div>
            )}
            styles={
              {
                LabelTitle: () => ({
                  fontSize: '.9rem',
                }),
                LineSeperator: () => ({
                  display: 'none',
                })
              }
            }
          />
          {isSubmitting &&
            <div className="loading-container">
              <p>{loadingTexts[loadingTextIndex]}</p>
              <div className="loader">
                <div></div>
                <div></div>
                <div></div>
                <div></div>
              </div>
            </div>
          }
        </form>

      </div>
      <Modal isOpen={open} onClose={() => setOpen(false)} images={outputImages} input={inputImage} />
    </>

  )
}

export default Form